module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pl"],"defaultLanguage":"pl","siteUrl":"https://lor.com.pl","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"redirect":true,"pages":[{"matchPath":"/:lang?/salony/:short","getLanguageFromPath":true,"excludeLanguages":["pl"]},{"matchPath":"/salony/:short","languages":["pl"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"L'Or","short_name":"L'Or","description":"Fryzjer Szczecin","lang":"pl","start_url":"/","display":"minimal-ui","icon":"src/images/logo-bird.svg","localize":[{"start_url":"/de/","lang":"de","name":"L'Or","short_name":"L'Or","description":"..."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58cbc0ef65132a0686980ba7d15a07f1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
